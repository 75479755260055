import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const ForgotPassword = Loadable(lazy(() => import('pages/authentication/forgotPassword')));
const PrivacyPolicy = Loadable(lazy(() => import('pages/authentication/PrivacyPolicy')));
const TermsAndConditions = Loadable(lazy(() => import('pages/authentication/TermsAndConditions')));
const FAQ = Loadable(lazy(() => import('pages/authentication/FAQ')));
const HowItWorks = Loadable(lazy(() => import('pages/authentication/HowItWorks')));
const DeleteAccount = Loadable(lazy(() => import('pages/delete-account/deleteAccount')));
const EmailVerification = Loadable(lazy(() => import('pages/delete-account/emailVerification')));
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <AuthLogin />
    },
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'forgotpassword',
      element: <ForgotPassword />
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicy />
    },
    {
      path: 'terms-and-conditions',
      element: <TermsAndConditions />
    },
    {
      path: 'faq',
      element: <FAQ />
    },
    {
      path: 'how-it-works',
      element: <HowItWorks />
    },
    {
      path: 'delete-account',
      element: <DeleteAccount />
    },
    {
      path: 'email-verification',
      element: <EmailVerification />
    }
  ]
};

export default LoginRoutes;
