// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
// import menuItem from 'menu-items';

import dashboard from '../../../../../menu-items/dashboard';
import arabicdashboard from '../../../../../menu-items/arabicdashboard';
import frenchdashboard from '../../../../../menu-items/frenchdashboard';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const language_id = localStorage.getItem('selected_language');

  const lan_Id = language_id == 1 ? 'arabic' : language_id == 3 ? 'french' : 'english';

  const menuItems = {
    items: [lan_Id === 'arabic' ? arabicdashboard : lan_Id == 'french' ? frenchdashboard : dashboard]
  };

  const navGroups = menuItems.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 0 }}>{navGroups}</Box>;
};

export default Navigation;
