import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  // IconButton,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Typography
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import ProfileTab from './ProfileTab';
// import SettingTab from './SettingTab';

// assets
// import avatar1 from 'assets/images/background_image/9350865.jpg';
import { UserOutlined } from '@ant-design/icons';
import axios from 'axios';
import data from '../../../../../translation.json';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`
  };
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme();
  const accessToken = localStorage.getItem('ACCESS_TOKEN');
  const role = localStorage.getItem('ROLE');
  const language_id = localStorage.getItem('selected_language');
  const lan_Id = language_id == 1 ? 'arabic' : language_id == 3 ? 'french' : 'english';

  const handleLogout = async () => {
    // logout
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [profile, setProfile] = useState([]);

  useEffect(() => {
    {
      if (!accessToken) {
        navigate('/');
      }

      if (role == 'staff') {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_CORE_HOST}/staff-profiles`,
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          data: ''
          // params:{"search":searchVal}
        };

        axios
          .request(config)
          .then((response) => {
            // if (response.data.status_code != 200) {
            //   navigate('/');
            // }
            const data = response.data;
            const all = data.data; //.detail.data.country
            setProfile(all);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (role == 'dealer_head') {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_CORE_HOST}/dealer-head-profiles`,
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          data: ''
          // params:{"search":searchVal}
        };

        axios
          .request(config)
          .then((response) => {
            // if (response.data.status_code != 200) {
            //   navigate('/');
            // }
            const data = response.data;
            const all = data.data; //.detail.data.country
            setProfile(all);
            // console.log('data', all);
            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [accessToken, role]);

  const iconBackColorOpen = 'grey';

  return (
    <>
      {data &&
        data.map(
          (lang) =>
            [lang] &&
            [lang].map((innerlang) =>
              lan_Id && lan_Id == innerlang.name ? (
                <Grid key={lang} xs={12} md={12} lg={12} sm={12}>
                  <Box sx={{ flexShrink: 0, ml: 0.75 }}>
                    <ButtonBase
                      sx={{
                        p: 0.25,
                        bgcolor: open ? iconBackColorOpen : '#FFFFFF',
                        borderRadius: 1,
                        border: '1px solid white',
                        '&:hover': { border: '1px solid #FF0000', backgroundColor: '#FFFFFF', color: '#FF0000' }
                      }}
                      aria-label="open profile"
                      ref={anchorRef}
                      aria-controls={open ? 'profile-grow' : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                    >
                      <UserOutlined />
                      {profile &&
                        profile.map((item) => (
                          <Stack key={item.firstName} direction="row" spacing={2} alignItems="center" sx={{ p: 0.5, pl: 2 }}>
                            <Avatar
                              alt={item.firstName.charAt(0).toUpperCase()}
                              src={item.user && item.user ? item.user.profileImage : ''}
                              sx={{ width: 32, height: 32 }}
                              style={{ marginRight: language_id == 1 ? '5px' : '0px', marginLeft: language_id == 1 ? '5px' : '0px' }}
                            >
                              {item.firstName.charAt(0).toUpperCase()}
                            </Avatar>
                            <Typography color="#000000" key={item.firstName} variant="subtitle1">
                              {item.firstName}
                            </Typography>
                          </Stack>
                        ))}
                    </ButtonBase>

                    <Popper
                      placement="bottom-end"
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                      popperOptions={{
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, 9]
                            }
                          }
                        ]
                      }}
                    >
                      {({ TransitionProps }) => (
                        <Transitions type="fade" in={open} {...TransitionProps}>
                          {open && (
                            <Paper
                              sx={{
                                boxShadow: theme.customShadows.z1,
                                width: 290,
                                minWidth: 240,
                                maxWidth: 290,
                                [theme.breakpoints.down('md')]: {
                                  maxWidth: 250
                                }
                              }}
                            >
                              <ClickAwayListener onClickAway={handleClose}>
                                <MainCard elevation={0} border={false} content={false}>
                                  <CardContent sx={{ px: 2.5, pt: 3 }}>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                      <Grid item>
                                        {profile &&
                                          profile.map((item) => (
                                            <Stack key={item.id} direction="row" spacing={1.25} alignItems="center">
                                              <Avatar
                                                alt={item.firstName.charAt(0).toUpperCase()}
                                                src={item.user && item.user ? item.user.profileImage : ''}
                                                sx={{ width: 32, height: 32 }}
                                              >
                                                {item.firstName.charAt(0).toUpperCase()}
                                              </Avatar>
                                              <Stack key={item.firstName}>
                                                <Typography variant="h6">{item.firstName}</Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                  {lan_Id && lan_Id == innerlang.name ? innerlang.translations.ISUZU : `ISUZU`}
                                                </Typography>
                                              </Stack>
                                            </Stack>
                                          ))}
                                      </Grid>
                                      <Grid item>
                                        {/* <IconButton size="large" color="secondary" onClick={handleLogout}>
                            <LogoutOutlined />
                          </IconButton> */}
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                  {open && (
                                    <>
                                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs
                                          TabIndicatorProps={{
                                            style: {
                                              backgroundColor: '#FF0000'
                                            }
                                          }}
                                          variant="fullWidth"
                                          value={value}
                                          onChange={handleChange}
                                          aria-label="profile tabs"
                                        >
                                          <Tab
                                            sx={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              justifyContent: language_id == 1 ? 'right' : 'left',
                                              alignItems: 'left',
                                              textTransform: 'capitalize',
                                              pl: 2
                                            }}
                                            icon={
                                              <UserOutlined
                                                style={{
                                                  marginBottom: 0,
                                                  marginRight: language_id == 1 ? '0px' : '20px',
                                                  color: '#000000',
                                                  marginLeft: language_id == 1 ? '20px' : '0px'
                                                }}
                                              />
                                            }
                                            label={
                                              <span style={{ color: '#000000' }}>
                                                {' '}
                                                {lan_Id && lan_Id == innerlang.name ? innerlang.translations.profile : `profile`}
                                              </span>
                                            }
                                            {...a11yProps(0)}
                                          />
                                          {/* <Tab
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'capitalize'
                              }}
                              icon={<SettingOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                              label="Setting"
                              {...a11yProps(1)}
                            /> */}
                                        </Tabs>
                                      </Box>
                                      <TabPanel value={value} index={0} dir={theme.direction}>
                                        <ProfileTab handleLogout={handleLogout} />
                                      </TabPanel>
                                      <TabPanel value={value} index={1} dir={theme.direction}>
                                        {/* <SettingTab /> */}
                                      </TabPanel>
                                    </>
                                  )}
                                </MainCard>
                              </ClickAwayListener>
                            </Paper>
                          )}
                        </Transitions>
                      )}
                    </Popper>
                  </Box>
                </Grid>
              ) : null
            )
        )}
    </>
  );
};

export default Profile;
