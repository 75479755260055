import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import Settings from 'pages/components-overview/settings';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
// const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Checklist = Loadable(lazy(() => import('pages/components-overview/checklist')));
const Questions = Loadable(lazy(() => import('pages/checklist/questions')));
const Reports = Loadable(lazy(() => import('pages/components-overview/reports')));
const WelcomeChecklist = Loadable(lazy(() => import('pages/components-overview/welcomechecklist')));
const ViewProfile = Loadable(lazy(() => import('pages/profile_section/view')));
const EditProfile = Loadable(lazy(() => import('pages/profile_section/editprofile')));
const NewChecklist = Loadable(lazy(() => import('pages/checklist/new_checklist')));
const PendingChecklist = Loadable(lazy(() => import('pages/checklist/pend_checklist')));
const CompletedChecklist = Loadable(lazy(() => import('pages/checklist/completed_checklist')));
const CompletedChecklistreport = Loadable(lazy(() => import('pages/checklist/completed_checklist_report')));
const Test = Loadable(lazy(() => import('pages/components-overview/test')));
const FinishChecklist = Loadable(lazy(() => import('pages/checklist/finish')));
const ResumeChecklist = Loadable(lazy(() => import('pages/checklist/resume')));
const NoReports = Loadable(lazy(() => import('pages/reports/no_reports')));
const Notifications = Loadable(lazy(() => import('pages/components-overview/notification')));
const NoChecklist = Loadable(lazy(() => import('pages/checklist/no_checklist')));
const NoNotification = Loadable(lazy(() => import('pages/notification/no_notification')));
const FrequentQues = Loadable(lazy(() => import('pages/components-overview/frequent_question')));
const NOFrequentQues = Loadable(lazy(() => import('pages/FAQ/nofaq')));
const Feedback = Loadable(lazy(() => import('pages/components-overview/feedback')));
const Help = Loadable(lazy(() => import('pages/components-overview/help')));
const ContactIIFAdmin = Loadable(lazy(() => import('layout/MainLayout/Header/HeaderContent/ContactIIFAdmin')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: 'dashboard',
      element: <DashboardDefault />
    },
    {
      path: 'checklist',
      element: <Checklist />
    },
    {
      path: 'checklist/welcomechecklist/questions',
      element: <Questions />
    },
    {
      path: 'settings',
      element: <Settings />
    },
    {
      path: 'reports',
      element: <Reports />
    },
    {
      path: 'checklist/welcomechecklist',
      element: <WelcomeChecklist />
    },
    {
      path: 'profile',
      element: <ViewProfile />
    },
    {
      path: 'editprofile',
      element: <EditProfile />
    },
    {
      path: 'checklist/newchecklist',
      element: <NewChecklist />
    },
    {
      path: 'checklist/pendingchecklist',
      element: <PendingChecklist />
    },
    {
      path: 'checklist/completedchecklist',
      element: <CompletedChecklist />
    },
    {
      path: 'checklist/completedchecklist/completedchecklistreport',
      element: <CompletedChecklistreport />
    },
    {
      path: 'test',
      element: <Test />
    },
    {
      path: 'finishchecklist',
      element: <FinishChecklist />
    },
    {
      path: 'resumechecklist',
      element: <ResumeChecklist />
    },
    {
      path: 'noreports',
      element: <NoReports />
    },
    {
      path: 'notifications',
      element: <Notifications />
    },
    {
      path: 'nochecklist',
      element: <NoChecklist />
    },
    {
      path: 'nonotification',
      element: <NoNotification />
    },
    {
      path: 'frequentquestions',
      element: <FrequentQues />
    },
    {
      path: 'nofrequentquestions',
      element: <NOFrequentQues />
    },
    {
      path: 'feedback',
      element: <Feedback />
    },
    {
      path: 'help',
      element: <Help />
    },
    {
      path: 'contactiifadmin',
      element: <ContactIIFAdmin />
    }
  ]
};

export default MainRoutes;
