import { SettingsNotifications } from 'pages/Settings/settingsnotification';
import React from 'react';
// import { Grid } from '../../../node_modules/@mui/material/index';
//   import { Title } from '../../../node_modules/@mui/icons-material/index';
import data from '../../translation.json';

const Settings = () => {
  const language_id = localStorage.getItem('selected_language');

  const lan_Id = language_id == 1 ? 'arabic' : language_id == 3 ? 'french' : 'english';

  return (
    <>
      {data &&
        data.map(
          (lang) =>
            [lang] &&
            [lang].map((innerlang) =>
              lan_Id && lan_Id == innerlang.name ? (
                <div key={lang} style={{ color: 'red' }}>
                  <h1>{lan_Id && lan_Id == innerlang.name ? innerlang.translations.Settings : `Settings`}</h1>
                </div>
              ) : null
            )
        )}
      <SettingsNotifications />
    </>
  );
};
export default Settings;
