import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  //   ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  // IconButton,
  Paper,
  Popper,
  Tab,
  Tabs
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
// import ProfileTab from './ProfileTab';
// import SettingTab from './SettingTab';

// assets
// import avatar1 from 'assets/images/background_image/9350865.jpg';
// import { UserOutlined } from '@ant-design/icons';
// import axios from 'axios';
import data from '../../../../translation.json';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { List, ListItemButton, ListItemText } from '../../../../../node_modules/@mui/material/index';
import { useNavigate } from '../../../../../node_modules/react-router/dist/index';
// import { useNavigate } from '../../../../node_modules/react-router-dom/dist/index';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`
  };
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Help = () => {
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState(0);
  //   const accessToken = localStorage.getItem('ACCESS_TOKEN');
  //   const role = localStorage.getItem('ROLE');
  const language_id = localStorage.getItem('selected_language');
  const lan_Id = language_id == 1 ? 'arabic' : language_id == 3 ? 'french' : 'english';
  const navigate = useNavigate();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const iconBackColorOpen = 'grey';

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);

    if (index == 1) {
      navigate('/contactiifadmin');
    }
  };

  return (
    <>
      {data &&
        data.map(
          (lang) =>
            [lang] &&
            [lang].map((innerlang) =>
              lan_Id && lan_Id == innerlang.name ? (
                <Grid key={lang} xs={12} md={12} lg={12} sm={12}>
                  <Box sx={{ flexShrink: 0, ml: 0.5 }}>
                    <Grid
                      sx={{
                        p: 0.25,
                        bgcolor: open ? iconBackColorOpen : '#FFFFFF',
                        borderRadius: 1,
                        border: '1px solid white',
                        '&:hover': { border: '1px solid #FF0000', backgroundColor: '#FFFFFF', color: '#FF0000' }
                      }}
                      aria-label="open profile"
                      ref={anchorRef}
                      aria-controls={open ? 'profile-grow' : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                    >
                      <HelpOutlineIcon sx={{ mt: 0.5 }} />
                    </Grid>

                    <Popper
                      placement="bottom-end"
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                      popperOptions={{
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, 9]
                            }
                          }
                        ]
                      }}
                    >
                      {({ TransitionProps }) => (
                        <Transitions type="fade" in={open} {...TransitionProps}>
                          {open && (
                            <Paper
                              sx={{
                                boxShadow: theme.customShadows.z1,
                                width: 290,
                                minWidth: 240,
                                maxWidth: 290,
                                [theme.breakpoints.down('md')]: {
                                  maxWidth: 250
                                }
                              }}
                            >
                              <ClickAwayListener onClickAway={handleClose}>
                                <MainCard elevation={0} border={false} content={false}>
                                  <CardContent sx={{ p: 0, pt: 2 }}>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                      <Grid item>
                                        {/* <IconButton size="large" color="secondary" onClick={handleLogout}>
                            <LogoutOutlined />
                          </IconButton> */}
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                  {open && (
                                    <>
                                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs
                                          TabIndicatorProps={{
                                            style: {
                                              backgroundColor: '#FF0000'
                                            }
                                          }}
                                          variant="fullWidth"
                                          value={value}
                                          onChange={handleChange}
                                          aria-label="profile tabs"
                                        >
                                          <Tab
                                            sx={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              justifyContent: 'left',
                                              alignItems: 'left',
                                              textTransform: 'capitalize',
                                              pl: 2
                                            }}
                                            // icon={<UserOutlined style={{ marginBottom: 0, marginRight: '10px', color: '#000000' }} />}
                                            label={
                                              <span style={{ color: '#000000' }}>
                                                {' '}
                                                {lan_Id && lan_Id == innerlang.name ? innerlang.translations.help : `Help`}
                                              </span>
                                            }
                                            {...a11yProps(0)}
                                          />
                                        </Tabs>
                                      </Box>
                                      <TabPanel value={value} index={0} dir={theme.direction}>
                                        <List
                                          component="nav"
                                          sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}
                                        >
                                          {/* <ListItemButton
                                            sx={{
                                              border: '1px solid transparent',
                                              '&:hover': { border: '1px solid #FF0000', backgroundColor: '#FFFFFF', color: '#FF0000' }
                                            }}
                                            //   selected={selectedIndex === 0}
                                            // onClick={(event) => handleListItemClick(event, 0)}
                                          >
                                            <ListItemText
                                              primary={
                                                lan_Id && lan_Id == innerlang.name
                                                  ? innerlang.translations.ContactDealerAdmin
                                                  : `Contact dealer admin`
                                              }
                                            />
                                          </ListItemButton> */}
                                          <ListItemButton
                                            sx={{
                                              border: '1px solid transparent',
                                              '&:hover': { border: '1px solid #FF0000', backgroundColor: '#FFFFFF', color: '#FF0000' }
                                            }}
                                            //   selected={selectedIndex === 0}
                                            // onClick={handleListItemClick}
                                            selected={selectedIndex === 1}
                                            onClick={(event) => handleListItemClick(event, 1)}
                                          >
                                            <ListItemText
                                              primary={
                                                lan_Id && lan_Id == innerlang.name
                                                  ? innerlang.translations.ContactIIFAdmin
                                                  : `Contact IIF admin`
                                              }
                                            />
                                          </ListItemButton>
                                          <a
                                            href={
                                              lan_Id && lan_Id == innerlang.name
                                                ? innerlang.translations.UserGuideLink
                                                : `https://isuzu-ds.s3.me-central-1.amazonaws.com/assets/help/Dealer+Portal.pdf`
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                            style={{ textDecoration: 'none', color: 'black' }}
                                          >
                                            <ListItemButton
                                              sx={{
                                                border: '1px solid transparent',
                                                '&:hover': { border: '1px solid #FF0000', backgroundColor: '#FFFFFF', color: '#FF0000' }
                                              }}
                                              //   selected={selectedIndex === 0}
                                              // onClick={(event) => handleListItemClick(event, 0)}
                                              selected={selectedIndex === 2}
                                              onClick={(event) => handleListItemClick(event, 2)}
                                            >
                                              <ListItemText
                                                primary={
                                                  lan_Id && lan_Id == innerlang.name ? innerlang.translations.UserGuides : `User guides`
                                                }
                                              />
                                            </ListItemButton>
                                          </a>
                                        </List>
                                      </TabPanel>
                                      <TabPanel value={value} index={1} dir={theme.direction}>
                                        {/* <SettingTab /> */}
                                      </TabPanel>
                                    </>
                                  )}
                                </MainCard>
                              </ClickAwayListener>
                            </Paper>
                          )}
                        </Transitions>
                      )}
                    </Popper>
                  </Box>
                </Grid>
              ) : null
            )
        )}
    </>
  );
};

export default Help;
