// material-ui
import { Box, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from 'react-redux';
import { openDrawer } from 'store/reducers/menu';
import PropTypes from 'prop-types';

// project import
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';
import Logo from 'components/Logo/Logo';
import { Grid, InputLabel, Typography } from '../../../../../node_modules/@mui/material/index';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Help from './help';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = ({ open }) => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const matchesLessThan1300px = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    console.log('clicked');
    dispatch(openDrawer({ drawerOpen: true }));
  };
  const handleClickClose = () => {
    console.log('clicked');
    dispatch(openDrawer({ drawerOpen: false }));
  };

  const [department, setDepartment] = useState(0);
  const [departments, setDepartments] = useState('');
  const role = localStorage.getItem('ROLE');
  const accessToken = localStorage.getItem('ACCESS_TOKEN');
  const navigate = useNavigate();
  const location = useLocation();
  const language_id = localStorage.getItem('selected_language');

  const handleChange = (event) => {
    setDepartment(event.target.value);
    localStorage.setItem('selected_department', event.target.value);
    // window.location.reload(false);
    navigate(`${location.pathname}`);
  };
  useEffect(() => {
    // setDropOpen(true);
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_CORE_HOST}/departments`,
      headers: {
        accept: 'application/json',
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: ''
    };

    axios
      .request(config)
      .then((response) => {
        const data = response.data;
        const all = data.data;

        setDepartments(all);

        if (response.status == 200) {
          // setDropOpen(false);
        }
      })
      .catch(() => {
        // setDropOpen(false);
      });
  }, [accessToken]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ paddingTop: '1em', width: '80px' }}>{<Logo />}</div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '2.5rem' }}>
          {matchesLessThan1300px ? open ? <MenuIcon onClick={handleClickClose} /> : <MenuIcon onClick={handleClickOpen} /> : null}
        </div>
      </div>

      {role && role == 'dealer_head' ? (
        <Grid container style={{ display: 'flex', justifyContent: language_id == 1 ? 'left' : 'right' }}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              {language_id == 1 ? 'قسم' : language_id == 3 ? 'Département' : 'Department'}
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={department}
              onChange={handleChange}
              label="language"
              sx={{ color: 'black' }}
            >
              <MenuItem sx={{ mt: '15%' }} value={0}>
                <Typography>ALL</Typography>
              </MenuItem>
              {departments &&
                departments.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      ) : null}
      <div style={{ display: 'flex', alignItems: 'center', color: 'black', paddingRight: '2em' }}>
        {matchesXs && <Box sx={{ flexGrow: 1 }} />}
        <Notification />
        <Help />
        {!matchesXs && <Profile />}
        {matchesXs && <MobileSection />}
      </div>
      {/* <span>Path : {location.pathname}</span> */}
    </>
  );
};

HeaderContent.propTypes = {
  open: PropTypes.bool
};

export default HeaderContent;
