import React from 'react';
import { Grid, Typography, TextField, Button, Snackbar, Alert, IconButton } from '../../../node_modules/@mui/material/index';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useFormik } from '../../../node_modules/formik/dist/index.js';
import InputAdornment from '@mui/material/InputAdornment';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import data from '../../translation.json';
import english from '../../english.json';
import arabic from '../../arabic.json';
import french from '../../french.json';

const pushNotifications = { inputProps: { 'aria-label': 'Push Notifications' } };
const emailNotifications = { inputProps: { 'aria-label': 'Email Notifications' } };

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const IsuzuSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#FF0000' : '#FF0000',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

export const SettingsNotifications = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setSuccessOpen(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const accessToken = localStorage.getItem('ACCESS_TOKEN');
  const navigate = useNavigate();
  const [profile, setProfile] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessasge] = React.useState('');
  const [successopen, setSuccessOpen] = React.useState(false);
  const [successMessage, setSucessMessasge] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [shownewPassword, setShowNewPassword] = React.useState(false);
  const [showconfirmPassword, setShowConfirmPassword] = React.useState(false);
  const role = localStorage.getItem('ROLE');
  const language_id = localStorage.getItem('selected_language');

  // const [password, setPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordStrengthColor, setPasswordStrengthColor] = useState(null);
  const [passwordMessages, setPasswordMessages] = useState([]);

  const lan_Id = language_id == 1 ? 'arabic' : language_id == 3 ? 'french' : 'english';

  const lan_file = language_id == 1 ? arabic : language_id == 3 ? french : english;

  useEffect(() => {
    {
      if (!accessToken) {
        navigate('/');
      }

      if (role == 'staff') {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_CORE_HOST}/staff-profiles`,
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          data: ''
          // params:{"search":searchVal}
        };

        axios
          .request(config)
          .then((response) => {
            // if (response.data.status_code != 200) {
            //   navigate('/');
            // }
            const data = response.data;
            const all = data.data; //.detail.data.country
            setProfile(all);
            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (role == 'dealer_head') {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_CORE_HOST}/dealer-head-profiles`,
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          data: ''
          // params:{"search":searchVal}
        };

        axios
          .request(config)
          .then((response) => {
            // if (response.data.status_code != 200) {
            //   navigate('/');
            // }
            const data = response.data;
            const all = data.data; //.detail.data.country
            setProfile(all);
            // console.log('data', all);
            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (role == 'admin') {
        let config7 = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_CORE_HOST}/admin-profiles`,
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          data: ''
          // params:{"search":searchVal}
        };

        axios
          .request(config7)
          .then((response) => {
            // if (response.data.status_code != 200) {
            //   navigate('/');
            // }
            const data = response.data;
            const all = data.data; //.detail.data.country
            setProfile(all);
            // console.log('data', all);
            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [accessToken, navigate, role]);

  const handlePasswordStrengthCheck = (password) => {
    const lengthRegex = /.{8,}/; // At least 8 characters
    const uppercaseRegex = /[A-Z]/; // At least 1 uppercase character
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/; // At least 1 special character
    const numberRegex = /[0-9]/; // At least 1 number

    const isLengthValid = lengthRegex.test(password);
    const isUppercaseValid = uppercaseRegex.test(password);
    const isSpecialCharValid = specialCharRegex.test(password);
    const isNumberValid = numberRegex.test(password);

    const messages = [];

    if (!isLengthValid) {
      messages.push(
        lan_file.PasswordshouldBeAtLeast8CharactersLong
          ? lan_file.PasswordshouldBeAtLeast8CharactersLong
          : 'Password should be at least 8 characters long.'
      );
    }
    if (!isUppercaseValid) {
      messages.push(lan_file.UppercaseCharacterIsMissing ? lan_file.UppercaseCharacterIsMissing : 'Uppercase character is missing.');
    }
    if (!isSpecialCharValid) {
      messages.push(lan_file.SpecialCharacterIsMissing ? lan_file.SpecialCharacterIsMissing : 'Special character is missing.');
    }
    if (!isNumberValid) {
      messages.push(lan_file.NumberIsMissing ? lan_file.NumberIsMissing : 'Number is missing.');
    }

    if (messages.length === 0) {
      setPasswordStrength('Strong');
      setPasswordStrengthColor('Green');
    } else if (messages.length === 2) {
      setPasswordStrength('Medium');
      setPasswordStrengthColor('Blue');
    } else {
      setPasswordStrength('Weak');
      setPasswordStrengthColor('Red');
    }

    setPasswordMessages(messages);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    formik.handleChange(event);
    // setPassword(newPassword);
    handlePasswordStrengthCheck(newPassword);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: '',
      password: '',
      confirmpassword: '',
      email: profile && profile.map((item) => (item.user && item.user ? item.user.email : null))
    },
    onSubmit: (values, { resetForm }) => {
      const { oldPassword, password, confirmpassword, email } = values;
      if (!oldPassword) {
        setOpen(true);
        setErrorMessasge(lan_file.OldPasswordIsRequired ? lan_file.OldPasswordIsRequired : 'Old password is required');
      }
      if (!password) {
        setOpen(true);
        setErrorMessasge(lan_file.NewPasswordIsRequired ? lan_file.NewPasswordIsRequired : 'New password is required');
      }
      if (!confirmpassword) {
        setOpen(true);
        setErrorMessasge(lan_file.ConfirmPasswordIsRequired ? lan_file.ConfirmPasswordIsRequired : 'Confirm password is required');
      }
      if (password === confirmpassword) {
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_CORE_HOST}/auth-management`,
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          data: {
            action: 'passwordChange',
            value: {
              user: {
                email: email[0]
              },
              oldPassword: oldPassword,
              password: password
            }
          }
        };

        // if (!response && !error)
        // navigate('/')
        // setOpen(true)
        // setErrorMessasge('Network issue')

        axios
          .request(config)
          .then((response) => {
            if (response.status === 201) {
              setSuccessOpen(true);
              setSucessMessasge(lan_file.succesfullyChanged ? lan_file.succesfullyChanged : 'Succesfully changed');
              resetForm({ values: '' });
              navigate('/settings');
            }
          })

          .catch((error) => {
            setOpen(true);
            setErrorMessasge(error.response.data.message);
          });
      } else {
        setOpen(true);
        setErrorMessasge(
          lan_file.NewPasswordAndConfirmPasswordMustBeSame
            ? lan_file.NewPasswordAndConfirmPasswordMustBeSame
            : 'New password and confirm password must same'
        );
      }
    }
  });

  return (
    <>
      {data &&
        data.map(
          (lang) =>
            [lang] &&
            [lang].map((innerlang) =>
              lan_Id && lan_Id == innerlang.name ? (
                <Grid key={lang} container xs={12} md={12} lg={12} sm={12}>
                  {/* Form */}
                  <Grid container directon="column" sx={{ mt: '1%', backgroundColor: '#FFFFFF' }}>
                    <Tabs
                      // orientation="vertical"
                      variant="scrollable"
                      value={value}
                      onChange={handleChange}
                      aria-label="Vertical tabs example"
                      sx={{ borderColor: 'divider' }}
                      textColor="#222"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: '#F00'
                        }
                      }}
                    >
                      <Tab label={lan_Id && lan_Id == innerlang.name ? innerlang.translations.MyProfile : `My Profile`} />
                      <Tab label={lan_Id && lan_Id == innerlang.name ? innerlang.translations.Changepass : `Change Password`} />
                      <Tab label={lan_Id && lan_Id == innerlang.name ? innerlang.translations.Notifications : `Notifications`} />
                    </Tabs>
                    {profile &&
                      profile.map((item) => (
                        <TabPanel key={item.id} value={value} index={0} xs={12} md={12} spacing={6} style={{ width: '100%' }}>
                          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
                            <Avatar alt={item.firstName.charAt(0).toUpperCase()} src={item.user && item.user ? item.user.profileImage : ''}>
                              {item.firstName.charAt(0).toUpperCase()}
                            </Avatar>
                          </Grid>
                          <br />
                          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
                            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
                              <Typography style={{ display: 'flex', justifyContent: language_id == 1 ? 'right' : 'left' }} variant="h5">
                                {lan_Id && lan_Id == innerlang.name ? innerlang.translations.Fname : `First Name`}:{' '}
                              </Typography>
                            </Grid>
                            <Grid xs={1} md={1}>
                              &nbsp;
                            </Grid>
                            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
                              <Typography style={{ display: 'flex', justifyContent: language_id == 1 ? 'right' : 'left' }} variant="h5">
                                {lan_Id && lan_Id == innerlang.name ? innerlang.translations.Lname : `Last Name`}:{' '}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
                            <Grid xs={5} md={5}>
                              <TextField
                                key={item.id}
                                type="text"
                                fullWidth
                                placeholder="First Name"
                                name="name"
                                variant="outlined"
                                value={item.firstName}
                                disabled
                                sx={{
                                  '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#000000'
                                  }
                                }}
                              />
                            </Grid>

                            <Grid xs={1} md={1}>
                              &nbsp;
                            </Grid>
                            <Grid xs={5} md={5}>
                              <TextField
                                type="text"
                                fullWidth
                                placeholder="Last Name"
                                name="name"
                                variant="outlined"
                                value={item.lastName}
                                disabled
                                sx={{
                                  '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#000000'
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>

                          <Grid container direction="row" xs={12} md={12} spacing={12} sx={{ m: '1rem', alignItems: 'center' }}>
                            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
                              <Typography style={{ display: 'flex', justifyContent: language_id == 1 ? 'right' : 'left' }} variant="h5">
                                {lan_Id && lan_Id == innerlang.name ? innerlang.translations.Dealername : `Dealer Name`}:{' '}
                              </Typography>
                            </Grid>
                            <Grid xs={1} md={1}>
                              &nbsp;
                            </Grid>
                            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
                              <Typography style={{ display: 'flex', justifyContent: language_id == 1 ? 'right' : 'left' }} variant="h5">
                                {lan_Id && lan_Id == innerlang.name ? innerlang.translations.Establishmentdate : `Establishment Date`}:{' '}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container direction="row" xs={12} md={12} spacing={12} sx={{ m: '1rem', alignItems: 'center' }}>
                            <Grid xs={5} md={5}>
                              <TextField
                                type="text"
                                fullWidth
                                placeholder="Dealer Name"
                                name="name"
                                variant="outlined"
                                value={item.branches && item.branches ? item.branches.company.name : ''}
                                disabled
                                sx={{
                                  '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#000000'
                                  }
                                }}
                              />
                            </Grid>
                            <Grid xs={1} md={1}>
                              &nbsp;
                            </Grid>
                            <Grid xs={5} md={5}>
                              <TextField
                                type="text"
                                fullWidth
                                placeholder="Establishment Date"
                                name="name"
                                variant="outlined"
                                value={
                                  item.branches && item.branches
                                    ? new Date(item.branches.company.establishedDate).toLocaleDateString('en-UK', { year: 'numeric' })
                                    : ''
                                }
                                disabled
                                sx={{
                                  '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#000000'
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container direction="row" xs={12} md={12} spacing={12} sx={{ m: '1rem', alignItems: 'center' }}>
                            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
                              <Typography style={{ display: 'flex', justifyContent: language_id == 1 ? 'right' : 'left' }} variant="h5">
                                {lan_Id && lan_Id == innerlang.name ? innerlang.translations.Branch : `Branch`}:{' '}
                              </Typography>
                            </Grid>
                            <Grid xs={1} md={1}>
                              &nbsp;
                            </Grid>
                            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
                              <Typography style={{ display: 'flex', justifyContent: language_id == 1 ? 'right' : 'left' }} variant="h5">
                                {lan_Id && lan_Id == innerlang.name ? innerlang.translations.Department : `Department`}:{' '}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container direction="row" xs={12} md={12} spacing={12} sx={{ m: '1rem', alignItems: 'center' }}>
                            <Grid xs={5} md={5}>
                              <TextField
                                type="text"
                                fullWidth
                                placeholder="Branch"
                                name="name"
                                variant="outlined"
                                value={item.branches && item.branches ? item.branches.name : ''}
                                disabled
                                sx={{
                                  '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#000000'
                                  }
                                }}
                              />
                            </Grid>
                            <Grid xs={1} md={1}>
                              &nbsp;
                            </Grid>
                            <Grid xs={5} md={5}>
                              <TextField
                                type="text"
                                fullWidth
                                placeholder="Department"
                                name="name"
                                variant="outlined"
                                value={item.departments && item.departments ? item.departments.name : ''}
                                disabled
                                sx={{
                                  '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#000000'
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container direction="row" xs={12} md={12} spacing={12} sx={{ m: '1rem', alignItems: 'center' }}>
                            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
                              <Typography style={{ display: 'flex', justifyContent: language_id == 1 ? 'right' : 'left' }} variant="h5">
                                {lan_Id && lan_Id == innerlang.name ? innerlang.translations.Phnumber : `Phone Number`}:{' '}
                              </Typography>
                            </Grid>
                            <Grid xs={1} md={1}>
                              &nbsp;
                            </Grid>
                            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
                              <Typography style={{ display: 'flex', justifyContent: language_id == 1 ? 'right' : 'left' }} variant="h5">
                                {lan_Id && lan_Id == innerlang.name ? innerlang.translations.Email : `Email Address`}:{' '}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container direction="row" xs={12} md={12} spacing={12} sx={{ m: '1rem', alignItems: 'center' }}>
                            <Grid xs={5} md={5}>
                              <TextField
                                type="text"
                                fullWidth
                                placeholder="Phone Number"
                                name="name"
                                variant="outlined"
                                value={item.user && item.user ? item.user.phoneNumber : ''}
                                disabled
                                sx={{
                                  '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#000000'
                                  }
                                }}
                              />
                            </Grid>
                            <Grid xs={1} md={1}>
                              &nbsp;
                            </Grid>
                            <Grid xs={5} md={5}>
                              <TextField
                                type="text"
                                fullWidth
                                placeholder="Email Address"
                                name="email"
                                variant="outlined"
                                value={item.user && item.user ? item.user.email : ''}
                                disabled
                                sx={{
                                  '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#000000'
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        </TabPanel>
                      ))}
                    <TabPanel value={value} index={1} xs={12} md={12} spacing={6} style={{ width: '100%' }}>
                      <form onSubmit={formik.handleSubmit}>
                        <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
                          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
                            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
                              <Typography style={{ display: 'flex', justifyContent: language_id == 1 ? 'right' : 'left' }} variant="h5">
                                {lan_Id && lan_Id == innerlang.name ? innerlang.translations.OldPassword : `Old Password`}
                                <sup style={{ color: 'red' }}>*</sup>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'start' }}>
                            <Grid xs={5} md={5}>
                              <TextField
                                type={showPassword ? 'text' : 'password'}
                                fullWidth
                                required
                                placeholder={lan_Id && lan_Id == innerlang.name ? innerlang.translations.OldPassword : `Old Password`}
                                name="oldPassword"
                                variant="outlined"
                                onChange={formik.handleChange}
                                value={formik.values.oldPassword}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        sx={{ color: 'grey' }}
                                      >
                                        {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
                            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
                              <Typography style={{ display: 'flex', justifyContent: language_id == 1 ? 'right' : 'left' }} variant="h5">
                                {lan_Id && lan_Id == innerlang.name ? innerlang.translations.NewPassword : `New Password`}
                                <sup style={{ color: 'red' }}>*</sup>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
                            <Grid xs={5} md={5}>
                              <Grid xs={12} md={12}>
                                <TextField
                                  type={shownewPassword ? 'text' : 'password'}
                                  required
                                  id="outlined-required"
                                  fullWidth
                                  placeholder={lan_Id && lan_Id == innerlang.name ? innerlang.translations.NewPassword : `New Password`}
                                  name="password"
                                  variant="outlined"
                                  onChange={handlePasswordChange}
                                  value={formik.values.password}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowNewPassword}
                                          onMouseDown={handleMouseDownNewPassword}
                                          sx={{ color: 'grey' }}
                                        >
                                          {shownewPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </Grid>
                              <Grid xs={12} md={12}>
                                <p
                                  style={{
                                    color: passwordStrengthColor
                                  }}
                                >
                                  {lan_Id && lan_Id == innerlang.name ? innerlang.translations.PasswordStrength : `Password Strength`} :{' '}
                                  {lan_Id && lan_Id == innerlang.name
                                    ? passwordStrength == 'Weak'
                                      ? innerlang.translations.Weak
                                      : passwordStrength == 'Strong'
                                      ? innerlang.translations.Strong
                                      : passwordStrength == 'Medium'
                                      ? innerlang.translations.Medium
                                      : ``
                                    : ''}
                                </p>
                                <ul>
                                  {passwordMessages.map((message, index) => (
                                    <li key={index}>{message}</li>
                                  ))}
                                </ul>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
                            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
                              <Typography style={{ display: 'flex', justifyContent: language_id == 1 ? 'right' : 'left' }} variant="h5">
                                {lan_Id && lan_Id == innerlang.name ? innerlang.translations.ConfirmPassword : `Confirm Password`}
                                <sup style={{ color: 'red' }}>*</sup>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
                            <Grid xs={5} md={5}>
                              <TextField
                                type={showconfirmPassword ? 'text' : 'password'}
                                fullWidth
                                required
                                placeholder={
                                  lan_Id && lan_Id == innerlang.name ? innerlang.translations.ConfirmPassword : `Confirm Password`
                                }
                                name="confirmpassword"
                                variant="outlined"
                                onChange={formik.handleChange}
                                value={formik.values.confirmpassword}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        onMouseDown={handleMouseDownConfirmPassword}
                                        sx={{ color: 'grey' }}
                                      >
                                        {showconfirmPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Button
                          type="submit"
                          style={{
                            backgroundColor: 'red',
                            color: 'white',
                            marginTop: '20px',
                            width: 'auto',
                            borderRadius: '8px',
                            marginLeft: '20px'
                          }}
                        >
                          {lan_Id && lan_Id == innerlang.name ? innerlang.translations.Changepass : `Change Password`}
                        </Button>
                      </form>
                    </TabPanel>
                    <Snackbar
                      open={open}
                      autoHideDuration={2000}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                      }}
                    >
                      <Alert onClose={handleClose} severity="error">
                        {errorMessage}
                      </Alert>
                    </Snackbar>
                    <Snackbar
                      open={successopen}
                      autoHideDuration={2000}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                      }}
                    >
                      <Alert onClose={handleClose} severity="success">
                        {successMessage}
                      </Alert>
                    </Snackbar>
                    <TabPanel value={value} index={2} xs={12} md={12} spacing={6} style={{ width: '100%' }}>
                      <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
                        <Grid xs={12} md={12}>
                          <Typography variant="h4">
                            {lan_Id && lan_Id == innerlang.name ? innerlang.translations.Trackyourchecklists : `Track your checklists`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
                        <Grid xs={12} md={12}>
                          <Typography variant="h5">
                            {lan_Id && lan_Id == innerlang.name
                              ? innerlang.translations.NevermissanyupcomingupdatesfromIsuzu
                              : `Never miss any upcoming updates from Isuzu`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
                        <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
                          <Typography style={{ display: 'flex', justifyContent: language_id == 1 ? 'right' : 'left' }} variant="h5">
                            {lan_Id && lan_Id == innerlang.name ? innerlang.translations.PushNotifications : `Push Notifications`}:{' '}
                          </Typography>
                        </Grid>
                        <Grid xs={1} md={1}>
                          &nbsp;
                        </Grid>
                        <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
                          <Typography style={{ display: 'flex', justifyContent: language_id == 1 ? 'right' : 'left' }} variant="h5">
                            {lan_Id && lan_Id == innerlang.name ? innerlang.translations.EmailNotifications : `Email Notifications`}:{' '}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
                        <Grid xs={5} md={5}>
                          <Typography>
                            {lan_Id && lan_Id == innerlang.name ? innerlang.translations.OFF : `OFF`}{' '}
                            <IsuzuSwitch {...pushNotifications} defaultChecked disabled />{' '}
                            {lan_Id && lan_Id == innerlang.name ? innerlang.translations.ON : `ON`}
                          </Typography>
                        </Grid>
                        <Grid xs={1} md={1}>
                          &nbsp;
                        </Grid>
                        <Grid xs={5} md={5}>
                          <Typography>
                            {lan_Id && lan_Id == innerlang.name ? innerlang.translations.OFF : `OFF`}{' '}
                            <IsuzuSwitch {...emailNotifications} defaultChecked disabled />{' '}
                            {lan_Id && lan_Id == innerlang.name ? innerlang.translations.ON : `ON`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </Grid>
                </Grid>
              ) : null
            )
        )}
    </>
  );
};
