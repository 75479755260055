import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  AppBar,
  // IconButton,
  Toolbar,
  useMediaQuery
} from '@mui/material';
// import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
// import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';

// assets

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({
  open
  // handleDrawerToggle
}) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  // const iconBackColor = 'grey';
  // const iconBackColorOpen = '#262626';

  // common header
  const mainHeader = (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      {/* <IconButton
        disableRipple
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start"
        color="secondary"
        sx={{
          color: 'white',
          bgcolor: open ? iconBackColorOpen : iconBackColor,
          ml: { xs: 0, lg: -2 },
          borderRadius: 1,
          '&:hover': { bgcolor: '#FF0000' }
        }}
      >
        {!open ? <FormatIndentIncreaseIcon /> : <FormatIndentDecreaseIcon />}
      </IconButton> */}
      <HeaderContent />
    </Toolbar>
  );

  // app-bar params
  const appBar = {
    position: 'fixed',
    color: 'inherit',
    elevation: 2,
    sx: {
      backgroundColor: '#FFFFFF'
    }
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func
};

export default Header;
