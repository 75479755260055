import { useRef, useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  Grid
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';

// assets
import { BellOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, ListItem, Tooltip } from '../../../../../node_modules/@mui/material/index';
import axios from 'axios';
import { Link } from '../../../../../node_modules/react-router-dom/dist/index';
import { useNavigate } from 'react-router-dom';
import data from '../../../../translation.json';

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const accessToken = localStorage.getItem('ACCESS_TOKEN');
  const [notifications, setNotifications] = useState([]);
  const [notificationscount, setNotificationsCount] = useState([]);
  const language_id = localStorage.getItem('selected_language');
  const lan_Id = language_id == 1 ? 'arabic' : language_id == 3 ? 'french' : 'english';

  const navigate = useNavigate();

  // const datetime = new Date().toISOString();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = 'grey';
  const iconBackColor = '#FFFFFF';

  const handleUpdate = () => {
    notificationscount.map((item) => {
      let config = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_CORE_HOST}/notifications/${parseInt(item.id)}`,
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          status: true
        }
      };

      try {
        axios.request(config).then((response) => {
          if (response.status === 200) {
            navigate('/notifications');
          }
        });
      } catch {
        (error) => {
          console.log('error', error.response.data.message);
        };
      }
    });
  };

  useEffect(() => {
    if (!accessToken) {
      navigate('/');
    }

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_CORE_HOST}/notifications?$and[0][notificationChannelId]=1&$and[1][status]=false&$limit=5`,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: ''
      // params:{"search":searchVal}
    };

    axios
      .request(config)
      .then((response) => {
        const data = response.data;
        const all = data.data;

        setNotifications(all);
      })
      .catch((error) => {
        console.log(error);
      });

    let config2 = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_CORE_HOST}/notifications?$and[0][notificationChannelId]=1&$and[1][status]=false&$limit=500`,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: ''
      // params:{"search":searchVal}
    };

    axios
      .request(config2)
      .then((response) => {
        const data = response.data;
        const all = data.data;

        setNotificationsCount(all);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [accessToken, navigate]);

  return (
    <>
      {data &&
        data.map(
          (lang) =>
            [lang] &&
            [lang].map((innerlang) =>
              lan_Id && lan_Id == innerlang.name ? (
                <Grid key={lang} xs={12} md={12} lg={12} sm={12}>
                  <Box sx={{ flexShrink: 0, ml: 0.75 }}>
                    <Tooltip
                      title={lan_Id && lan_Id == innerlang.name ? innerlang.translations.Notifications : `Notifications`}
                      placement="bottom"
                    >
                      <IconButton
                        disableRipple
                        color="secondary"
                        sx={{
                          color: '#262626',
                          bgcolor: open ? iconBackColorOpen : iconBackColor,
                          borderRadius: 1,
                          border: '1px solid white',
                          '&:hover': { border: '1px solid #FF0000', backgroundColor: '#FFFFFF', color: '#FF0000' }
                        }}
                        aria-label="open profile"
                        ref={anchorRef}
                        aria-controls={open ? 'profile-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                      >
                        <Badge badgeContent={notificationscount.length} color="error">
                          <BellOutlined />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                    <Popper
                      placement={matchesXs ? 'bottom' : 'bottom-end'}
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                      popperOptions={{
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [matchesXs ? -5 : 0, 9]
                            }
                          }
                        ]
                      }}
                    >
                      {({ TransitionProps }) => (
                        <Transitions type="fade" in={open} {...TransitionProps}>
                          <Paper
                            sx={{
                              boxShadow: theme.customShadows.z1,
                              width: '100%',
                              minWidth: 285,
                              maxWidth: 420,
                              [theme.breakpoints.down('md')]: {
                                maxWidth: 285
                              }
                            }}
                          >
                            <ClickAwayListener onClickAway={handleClose}>
                              <MainCard
                                title={lan_Id && lan_Id == innerlang.name ? innerlang.translations.Notifications : `Notifications`}
                                elevation={0}
                                border={false}
                                content={false}
                                secondary={
                                  <IconButton size="small" onClick={handleToggle}>
                                    <CloseOutlined />
                                  </IconButton>
                                }
                              >
                                <List
                                  component="nav"
                                  sx={{
                                    p: 0,
                                    '& .MuiListItemButton-root': {
                                      py: 0.5,
                                      '& .MuiAvatar-root': avatarSX,
                                      '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                                    }
                                  }}
                                >
                                  {notifications.length ? (
                                    <Divider>
                                      {notifications &&
                                        notifications.map((item) => (
                                          // console.log(item);

                                          <ListItem sx={{ p: '8px' }} key={item}>
                                            <ListItemAvatar>
                                              <Avatar
                                                sx={{
                                                  color: 'error.main',
                                                  bgcolor: 'error.lighter'
                                                }}
                                              >
                                                <BellOutlined />
                                              </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                              primary={
                                                <Typography variant="h6">
                                                  {item.message.length > 21 ? item.message.substr(0, 21) + '...' : item.message}
                                                  <Typography component="span" variant="subtitle1"></Typography>{' '}
                                                </Typography>
                                              }
                                              // secondary={Math.trunc(Math.ceil(new Date(datetime).getTime() - new Date(item.createdAt).getTime()) / 60000)}
                                            />
                                            <ListItemSecondaryAction>
                                              <Typography variant="caption" noWrap></Typography>
                                            </ListItemSecondaryAction>
                                          </ListItem>
                                        ))}
                                    </Divider>
                                  ) : (
                                    <Typography
                                      style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItem: 'center' }}
                                      variant="text"
                                    >
                                      {lan_Id && lan_Id == innerlang.name ? innerlang.translations.NoNotifications : `No Notifications`}
                                    </Typography>
                                  )}
                                  <ListItemButton onClick={handleClose} sx={{ textAlign: 'center', py: `${12}px !important` }}>
                                    <ListItemText
                                      primary={
                                        <Link to={'/notifications'} style={{ TextDecoder: 'none', color: 'transparent' }}>
                                          <Button
                                            sx={{
                                              border: '1px solid transparent',
                                              '&:hover': { border: '1px solid #FF0000', backgroundColor: '#FFFFFF', color: '#FF0000' }
                                            }}
                                            onClick={handleUpdate}
                                          >
                                            <Typography variant="h6" color="error">
                                              {lan_Id && lan_Id == innerlang.name ? innerlang.translations.ViewAll : `View All`}
                                            </Typography>
                                          </Button>
                                        </Link>
                                      }
                                    />
                                  </ListItemButton>
                                </List>
                              </MainCard>
                            </ClickAwayListener>
                          </Paper>
                        </Transitions>
                      )}
                    </Popper>
                  </Box>
                </Grid>
              ) : null
            )
        )}
    </>
  );
};

export default Notification;
