import logoImage from '../../assets/images/logo/logo.jpeg';
// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  // const theme = useTheme();

  const height = '80px';
  const width = 'auto';
  const marginTop = '-10px';
  const marginLeft = '-20px';
  return (
    <>
      <img src={logoImage} alt="Logo" style={{ height, width, marginTop, marginLeft }} />
    </>
  );
};

export default Logo;
